import { AtlasOptions, AtlasValue, AtlasValues } from 'atlas-ds';
import { Entreprise } from 'services/model';

interface CompaniesListProps {
    onSelect: (entreprise: any) => void
    entreprises: Entreprise[];
}

export default function CompaniesList(props: CompaniesListProps) {
    return <AtlasOptions
        options={props.entreprises.map(entreprise => ({
            id: entreprise.id,
            onClick: () => props.onSelect(entreprise),
            content: <AtlasValues>
                <AtlasValues.Grid>
                    <AtlasValue
                        label="Nom commercial"
                        value={entreprise.name}
                    />
                    <AtlasValue
                        label="SIREN"
                        value={entreprise.siren ? entreprise.siren : entreprise.siret?.substring(0, 9)}
                    />
                </AtlasValues.Grid>
                { entreprise.adresse.trim()
                    ? <AtlasValue
                        label="Adresse"
                        value={entreprise.adresse}
                    />: <></>
                }
            </AtlasValues>
        })) || []}
        loader={<AtlasValues>
            <AtlasValues.Grid>
                <AtlasValue label="Nom commercial" value="" />
                <AtlasValue label="SIREN" value="" />
            </AtlasValues.Grid>
        </AtlasValues>}
    />
}
