import { AtlasCheckboxProps } from 'atlas-ds'
import { Session as AuthSession } from 'next-auth'
import { signIn, signOut, useSession } from 'next-auth/react'
import { useEntreprise } from 'pages/_app'
import useSWR from 'swr'
import { formatAPIDate, headersCampus, headersMyAtlas, headersPublic, parseAPIDate, pathify, setSearchParams, toSearchParams, toSearchString } from './global'
import { Bandeau, Branche, Domaine, Entreprise, Lieu, Module, OrganismeFormation, Profil, Refs, Region, Session, Thematique } from './model'
import { MAINTENANCE_MODE } from './server'
import useFetchPagedAPI from './useFetchPagedAPI'

export function login(prompt: string) {
    signIn('atlas', undefined, { prompt: prompt })
}


export interface Paging {
    tri: string
    ordre: string
    parPage: number
    page: number
    v: number
}

export interface Page<T> {
    total: number
    data: T[]
}

export function useAPIServices() {
    const { data: authSession, status: authenticating } = useSession()

    const key = (authenticating !== 'loading' && authSession && authSession.user && authSession.accessToken) ?
        [`${process.env.NEXT_PUBLIC_SEL_BASE_API_URL}/comptes/profil/services`, authSession] :
        null

    return useSWR(key, async ([url, authSession]) => {
        const res = await fetch(`${url}`, { headers: headersMyAtlas(authSession as AuthSession) })

        if (res.status === 503) {
            throw new Error(MAINTENANCE_MODE)
        }
        if (!res.ok) {
            throw new Error('Error ' + res.status + ' => ' + res.statusText)
        }

        const services = await res.json()
        return services as string[]
    })
}

export function useAPIProfil() {
    const { data: authSession, status: authenticating } = useSession()

    const key = (authenticating !== 'loading' && authSession && authSession.user && authSession.accessToken) ?
        [`${process.env.NEXT_PUBLIC_SEL_BASE_API_URL}/comptes/profil`, 'EntrepriseCliente', authSession] :
        null

    return useSWR(key, async ([url, type, authSession]) => {
        const params = toSearchParams({ type: type as string })
        const res = await fetch(`${url}?${params.toString()}`, { headers: headersMyAtlas(authSession as AuthSession) })

        if (res.status === 503) {
            throw new Error(MAINTENANCE_MODE)
        }
        if (res.status === 401) {
            // 401 => supprime cookie de session
            await signOut({ redirect: false })
            return null
        }
        else if (!res.ok) {
            throw new Error('Error ' + res.status + ' => ' + res.statusText)
        }

        const profil = await res.json()
        const branches = await getBranches()

        return {
            firstName: profil.Contact.FirstName,
            lastName: profil.Contact.LastName,
            entreprises: profil?.Entreprises?.map((e: any) => ({ 
                id: e.Id,
                name: e.Name,
                siret: e.SIRET__c,
                adresse: (e.Adresse3__c ?? '') + ' ' + (e.BillingPostalCode ?? '') + ' ' + (e.BillingCity ?? ''),
                branche: branches?.find(b => b.idBranche === e.Branche__c),
            }))
        } as Profil
    })
}

async function fetchPublicAPI<T>(url: string): Promise<T> {
    return await fetch(
        `${process.env.NEXT_PUBLIC_SEL_PUBLIC_API_URL}${url}`,
        { headers: headersPublic() }
    ).then(res => res.json())
}

async function fetchCampusAPI<T>(path: string, options?: { params?: URLSearchParams, paging?: Paging, authSession?: AuthSession | null }): Promise<T> {
    let params = options?.params
    if (options?.paging) {
        params = setSearchParams(params ?? new URLSearchParams(), {
            page: options.paging.page?.toFixed(0),
            'par-page': options.paging.parPage?.toFixed(0)
        })
    }
    const url = `${path}${params ? `?${params.toString()}` : ''}`
    
    let res = await fetch(
        `${process.env.NEXT_PUBLIC_CAMPUS_BASE_API_URL}${url}`,
        { headers: headersCampus(options?.authSession) }
    )

    if (res.status === 503) {
        throw new Error(MAINTENANCE_MODE)
    }
    if (res.status === 401) {
        // 401 => supprime cookie de session
        await signOut({ redirect: false })
        // Relance sans authentification
        res = await fetch(url, { headers: headersCampus(null) })
    }
    
    if (!res.ok)
        throw new Error('Error ' + res.status + ' => ' + res.statusText)

    const data = await res.json()

    if (options?.paging) {
        return {
            total: res.headers.has('X-Total-Count') ? parseInt(res.headers.get('X-Total-Count') ?? '0') : 0,
            data
        } as unknown as T
    }

    return data
}


export async function getRefs(): Promise<Refs> {
    const value = sessionStorage.getItem('campus/refs')
    
    if (value)
        return JSON.parse(value)
    
    const data = await fetchCampusAPI<Refs>('/refs')
    sessionStorage.setItem('campus/refs', JSON.stringify(data))
    return data
}

export async function getBranches(): Promise<Branche[]> {
    const refs = await getRefs()
    
    return refs.REF_BRANCHE.map(b => ({
        id: b.IdHeroku,
        idBranche: b.Id,
        path: pathify(b.Libelle__c ?? ''),
        titre: b.Libelle__c,
    }))
}

export async function getRegions(): Promise<Region[]> {
    const refs = await getRefs()
    
    return refs.REF_REGION.map(b => ({
        id: b.IdHeroku,
        label: b.Libelle__c,
    }))
}

export function useAPIRechercheEntreprises(wait: boolean, recherche: string) {
    const { status: authenticating } = useSession()

    const key = (!wait && authenticating !== 'loading') ?
        ['/entreprises', recherche] :
        null

    return useSWR(
        key,
        async ([url, recherche]) => {
            const params = toSearchParams({ recherche })
            const entreprises = await fetchCampusAPI<any>(url, { params })
            const branches = await getBranches()
            
            return entreprises?.map((e: any) => ({ 
                id: e.Id,
                name: e.Name,
                siren: e.SIREN__c,
                adresse: (e.Adresse3__c ?? '') + ' ' + (e.BillingPostalCode ?? '') + ' ' + (e.BillingCity ?? ''),
                branche: branches?.find(b => b.idBranche === e.Branche__c)
            })) as Entreprise[]
        },
        { shouldRetryOnError: false }
    )
}

export function useAPIDomaines(branche?: Branche) {
    const { data: authSession, status: authenticating } = useSession()

    const key = (authenticating !== 'loading' && branche?.idBranche) ?
        ['/domaines', branche.idBranche, authSession?.accessToken] :
        null
    
    return useSWR(key, async ([_, idBranche]) => {
        const refs = await getRefs()

        const domains = refs.REF_CAMPUS_DOMAINE
            .filter(d => d.Branche.includes(idBranche!))
            .map(d => ({
                id: d.IdHeroku,
                path: pathify(d.Libelle__c ?? ''),
                titre: d.Libelle__c,
                branches: d.Branche,
            }))
        
        return domains as Domaine[]
    })
}

export function useAPIThematiques(params: {pageSize: number, domaine?: Domaine, fne?: boolean, page?: number}) {
    const entreprise = useEntreprise()

    return useFetchPagedAPI<Thematique>(
        { tri: '', ordre: 'asc', parPage: params.pageSize, page: params.page ?? 0, v: 0 },
        (authSession, authenticating, paging) => keyAPIThematiques(authSession, authenticating, params.domaine, params.fne, entreprise, paging),
        (authSession, authenticating, paging) => fetchAPIThematiques(authSession, authenticating, params.domaine, params.fne, entreprise, paging),
    )
}

function keyAPIThematiques(authSession: AuthSession | null, authenticating: boolean, domaine: Domaine | undefined, fne: boolean | undefined, entreprise: Entreprise | null, paging: Paging) {
    if (authenticating)
        return null

    const user = authSession && authSession.accessToken ? authSession.user!.email : 'anonymous'

    return `${user}@/thematiques?${toSearchParams({
        page: paging.page?.toFixed(0),
        'par-page': paging.parPage?.toFixed(0),
        'tri': 'libelle__c',
        'id-domaine': domaine?.id,
        'id-branche': entreprise?.branche?.idBranche,
        'fne': fne ? "true" : undefined
    })}`
}

async function fetchAPIThematiques(authSession: AuthSession | null, authenticating: boolean, domaine: Domaine | undefined, fne: boolean | undefined, entreprise: Entreprise | null, paging: Paging): Promise<Page<Thematique>> {
    const params = toSearchParams({
        'tri': 'libelle__c',
        'id-domaine': domaine?.id,
        'id-branche': entreprise?.branche?.idBranche,
        'fne': fne ? "true" : undefined,
    })
    const res = await fetchCampusAPI<Page<any>>('/thematiques', { params, paging, authSession })

    return {
        total: res.total,
        data: res?.data?.map(t => ({ 
            id: t.IdHeroku,
            path: pathify(t.Libelle__c ?? ''),
            titre: t.Libelle__c,
            branche: t.Branche__c,
            presentation: t.Presentation__c,
            stages: parseInt(t.NbModules),
            fne: !!t.Cofinancement__c
        })) ?? []
    }
}

export interface FiltresModules {
    modalites: AtlasCheckboxProps[]
    types: AtlasCheckboxProps[]
}

export const filtresModulesChanged = (f1: FiltresModules, f2: FiltresModules) => {
    if (optionsChanged(f1.modalites, f2.modalites))
        return true
    if (optionsChanged(f1.types, f2.types))
        return true
    return false
}

export function keyAPIThematiqueModules(
    authSession: AuthSession | null,
    authenticating: boolean,
    thematique: Thematique | undefined,
    paging: Paging,
    filtres: FiltresModules) {

    if (authenticating || !thematique)
        return null

    const user = authSession && authSession.accessToken ? authSession.user!.email : 'anonymous'
    return `${user}@/thematique/modules?${toSearchParams({
        page: paging.page?.toFixed(0),
        'par-page': paging.parPage?.toFixed(0),
        'id-thematique': thematique?.id,
        'id-modalite': filtres.modalites.map(option => option.value),
        'id-type': filtres.types.map(option => option.value),
    })}`
}

export function keyAPIOFModules (
    authSession: AuthSession | null,
    authenticating: boolean,
    of: OrganismeFormation | undefined,
    paging: Paging,
    filtres: FiltresModules) {

    if (authenticating || !of)
        return null

    const user = authSession && authSession.accessToken ? authSession.user!.email : 'anonymous'
    return `${user}@/ofs/modules?${toSearchParams({
        page: paging.page?.toFixed(0),
        'par-page': paging.parPage?.toFixed(0),
        'id-entreprise': of?.id?.toFixed(0),
        'id-modalite': filtres.modalites.map(option => option.value),
        'id-type': filtres.types.map(option => option.value),
    })}`
}

export async function fetchAPIModules(
    authSession: AuthSession | null,
    authenticating: boolean,
    thematique: Thematique | undefined,
    of: OrganismeFormation | undefined,
    paging: Paging,
    filtres: FiltresModules): Promise<Page<Module>> {

    const params = toSearchParams({
        'id-thematique': thematique?.id,
        'id-entreprise': of?.id?.toFixed(0),
        'id-modalite': filtres.modalites.map(option => option.value),
        'id-type': filtres.types.map(option => option.value),
    })
    
    const res = await fetchCampusAPI<Page<any>>('/modules', { params, paging, authSession })
    return {
        total: res.total,
        data: res.data?.map(m => ({ 
            id: m.IdHeroku,
            path: pathify(m.Libelle__c ?? ''),
            titre: m.Libelle__c,
            organismes: parseInt(m.NbOFs),
            sessions: parseInt(m.NbSessions),
            stages: m.Stages ? m.Stages.split(',')?.map((s: string) => parseInt(s)) : null,
            fne: !!m.Thematique.Cofinancement__c
        })) ?? [],
    }
}


export interface FiltresSessions {
    dateDebut: Date | null
    dateFin: Date | null
    lieux: Lieu[]
    modalites: AtlasCheckboxProps[]
    types: AtlasCheckboxProps[]
    ofs: AtlasCheckboxProps[]
    options: AtlasCheckboxProps[]
}

const villes = (f: FiltresSessions) => {
    return f.lieux.filter(l => l.ville).map(l => l.ville!)
}

const regions = (f: FiltresSessions) => {
    return f.lieux.filter(l => l.region?.id).map(l => l.region!.id)
}

function optionsChanged(o1: AtlasCheckboxProps[], o2: AtlasCheckboxProps[]) {
    if (o1.length !== o2.length)
        return true
    const ids1 = o1.map(o => o.value).sort()
    const ids2 = o2.map(o => o.value).sort()
    return !ids1.every((id1, index) => id1 === ids2[index])
}

export const filtresSessionsChanged = (f1: FiltresSessions, f2: FiltresSessions) => {
    if (f1.dateDebut !== f2.dateDebut)
        return true
    if (f1.dateFin !== f2.dateFin)
        return true
    if (optionsChanged(f1.modalites, f2.modalites))
        return true
    if (optionsChanged(f1.options, f2.options))
        return true
    if (optionsChanged(f1.types, f2.types))
        return true
    if (optionsChanged(f1.ofs, f2.ofs))
        return true
    if (villes(f1).sort().toString() !== villes(f2).sort().toString())
        return true
    if (regions(f1).sort().toString() !== regions(f2).sort().toString())
        return true
    return false
}

export function keyAPISessions(
    authSession: AuthSession | null,
    authenticating: boolean,
    module: Module | undefined,
    paging: Paging,
    filtres: FiltresSessions) {

    if (authenticating || !module)
        return null 
    
    const user = authSession && authSession.accessToken ? authSession.user!.email : 'anonymous'

    return `${user}@/module/${module.id}/sessions?${toSearchString({
        tri: paging.tri,
        ordre: paging.ordre,
        page: paging.page?.toFixed(0),
        'par-page': paging.parPage?.toFixed(0),
        dd: formatAPIDate(filtres.dateDebut),
        df: formatAPIDate(filtres.dateFin),
        lieu: villes(filtres),
        reg: regions(filtres),
        mod: filtres.modalites.map(option => option.value),
        type: filtres.types.map(option => option.value),
        of: filtres.ofs.map(option => option.value),
        'session-garantie': filtres.options?.find(option => option.value === 'garantie') !== undefined ? 'true' : undefined,
        'formation-certifiante': filtres.options?.find(option => option.value === 'certifiante') ? 'true' : undefined,
    })}}`
}

export async function fetchAPISessions(
    authSession: AuthSession | null,
    authenticating: boolean,
    module: Module | undefined,
    paging: Paging,
    filtres: FiltresSessions): Promise<Page<Session>> {

    const params = toSearchParams({
        tri: paging.tri,
        ordre: paging.ordre,
        'id-module': module?.id,
        lieu: villes(filtres),
        'id-region': regions(filtres),
        'date-debut': formatAPIDate(filtres.dateDebut),
        'date-fin': formatAPIDate(filtres.dateFin),
        'id-modalite': filtres.modalites.map(option => option.value),
        'id-type': filtres.types.map(option => option.value),
        'id-entreprise': filtres.ofs.map(option => option.value),
        'session-garantie': filtres.options?.find(option => option.value === 'garantie') !== undefined ? 'true' : undefined,
        'formation-certifiante': filtres.options?.find(option => option.value === 'certifiante') !== undefined ? 'true' : undefined,
    })

    const res = await fetchCampusAPI<Page<any>>('/sessions', { params, paging, authSession })
    
    return {
        total: res.total,
        data: res.data?.map(s => ({
            id: s.IdHeroku,
            sfid: s.Id,
            titre: s.Libelle__c,
            branches: module?.thematique?.branches,
            stage: {
                id: s.Stage.IdHeroku,
                path: pathify(s.Stage.Libelle__c ?? ''),
                titre: s.Stage.Libelle__c,
                of: {
                    id: s.Stage.EtablissementOF.IdHeroku, 
                    name: s.Stage.EtablissementOF.Name,
                },
                dureeHeures: s.Stage.DureeHeures__c,
                coutInter: s.Stage.CoutInter__c,
                coutIntra: s.Stage.CoutIntra__c,
                certification: s.Stage.Certification__c,
                certificationObligatoire: s.Stage.CertificationObligatoire__c,
                coutCertification: s.Stage.CoutCertification__c,
                supportCours: s.Stage.SupportCours__c,
                coutSupportCours: s.Stage.CoutSupportCours__c,
                coutintraEtrRevise:  s.Stage.CoutintraEtrRevise__c,
                coutinterEtrRevise: s.Stage.CoutinterEtrRevise__c,
                coutSupportDeCoursRevise: s.Stage.CoutSupportDeCoursRevise__c,
                coutCertificationRevise: s.Stage.CoutCertificationRevise__c,
            },
            lieu: {
                id: s.Adresse.IdHeroku,
                ville: s.Adresse.Ville__c,
                region: s.Adresse.Region__c,
            },
            dateDebut: parseAPIDate(s.DateDebut__c),
            dateFin: parseAPIDate(s.DateFin__c),
            heureDebut: s.HeureDebut__c,
            heureFin: s.HeureFin__c,
            type: s.Type__c,
            modalite: s.Modalite__c,
            coutTotal: s.CoutTotal__c ?? null,
            sessionGarantie: s.SessionGarantie__c,
            nbPlaces: s.NbPlacesDisponibles__c,
            fne: !!s.Thematique.Cofinancement__c
        }) as Session) ?? []
    }
}

export function useAPIModulesHome(pageSize: number, branche?: Branche) {
    return useFetchPagedAPI<Module>(
        { tri: '', ordre: 'asc', parPage: pageSize, page: 0, v: 0 },
        (authSession, authenticating, paging) => keyAPIModulesHome(authSession, authenticating, branche, paging),
        (authSession, authenticating, paging) => fetchAPIModulesHome(authSession, authenticating, branche, paging),
    )
}

function keyAPIModulesHome(authSession: AuthSession | null, authenticating: boolean, branche: Branche | undefined, paging: Paging) {
    if (authenticating)
        return null
    
    const user = authSession && authSession.accessToken ? authSession.user!.email : 'anonymous'
    return `${user}@/home/modules?${toSearchString({
        branche: branche?.id,
        page: paging.page?.toFixed(0),
        'par-page': paging.parPage?.toFixed(0)
    })}`
}

async function fetchAPIModulesHome(authSession: AuthSession | null, authenticating: boolean, branche: Branche | undefined, paging: Paging): Promise<Page<Module>> {
    const params = toSearchParams({
        aleatoire: 'true',
        'id-branche': branche?.idBranche,
        fne: 'true',
    })

    const res = await fetchCampusAPI<Page<any>>('/modules', { params, paging, authSession })
    return {
        total: res.total,
        data: res.data?.map(m => { 
            return {
                id: m.IdHeroku,
                path: pathify(m.Libelle__c ?? ''),
                titre: m.Libelle__c,
                organismes: parseInt(m.NbOFs),
                sessions: parseInt(m.NbSessions),
                fne: !!m.Thematique.Cofinancement__c
            } as Module
        }) ?? [],
    }
}


export async function fetchAPIStats() {
    const data = await fetchCampusAPI<any>('/stats')

    return {
        inscrits: data.nbInscrits,
        formations: data.nbFormations,
        modules: data.nbModules
    }
}

export async function fetchAPIBandeaux(): Promise<Bandeau[]> {
    return await fetchPublicAPI('/config/parametres?categorie=BANDEAUX_INFORMATIONS')
}

export async function fetchAPIBranches() {
    const data = await getBranches()

    return {
        branches: data.length,
    }
}


export function useAPIRechercheModules(wait: boolean, recherche: string) {
    const { data: authSession, status: authenticating } = useSession()
    const entreprise = useEntreprise()

    const key = (!wait && authenticating !== 'loading') ?
        ['/modules', recherche, authSession, entreprise?.branche] :
        null

    return useSWR(
        key,
        async ([url, recherche, authSession, branche]) => {
            const params = toSearchParams({
                recherche: recherche as string,
                'id-branche': (branche as Branche | null)?.idBranche
            })
            const modules = await fetchCampusAPI<any>(url as string, { params, authSession: authSession as AuthSession })

            const thematiques: Thematique[] = []
            modules.forEach((m: any) => {
                let thematique = thematiques.find(t => t.id === m.Thematique?.IdHeroku)
                if (!thematique) {
                    thematique = { 
                        id: m.Thematique?.IdHeroku, 
                        path: pathify(m.Thematique?.Libelle__c ?? ''), 
                        titre: m.Thematique?.Libelle__c,
                        modules: [{
                            id: m.IdHeroku,
                            path: pathify(m.Libelle__c ?? ''),
                            titre: m.Libelle__c,
                        }],
                        fne: !!m.Thematique.Cofinancement__c
                    }
                    thematiques.push(thematique)
                }
                else {
                    thematique.modules?.push({
                        id: m.IdHeroku,
                        path: pathify(m.Libelle__c ?? ''),
                        titre: m.Libelle__c,
                    })
                }
            })
            return thematiques
        },
        { shouldRetryOnError: false }
    )
}
