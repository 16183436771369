import { Session } from 'next-auth'
import { useSession } from 'next-auth/react'
import { useState } from 'react'
import useSWRInfinite from 'swr/infinite'
import { Paging } from './client'


export default function useFetchPagedAPI<T>(
    initialPaging: Paging,
    key: (authSession: Session | null, authenticating: boolean, paging: Paging) => string | null,
    fetch: (authSession: Session | null, authenticating: boolean, paging: Paging) => Promise<{
        total: number
        data: T[]
    }>,
) {

    const { data: authSession, status: authenticating } = useSession()

    const [ paging, setPaging ] = useState({ ...initialPaging, page: initialPaging.page ?? 0, v: 0 })
    const keyData = (pageNumber: number) => {
        const pageKey = key(authSession, authenticating === 'loading', { ...paging, page: pageNumber })
        // console.log('useFetchPagedAPI: key = ' + pageKey)
        if (!pageKey)
            return null
        return pageKey + `&vp=${paging.v}`
    }
    const fetchData = (key: string) => {
        // console.log('useFetchPagesAPI: fetch ' + key)
        const pageNumber = parseInt(key.substring(key.indexOf('page=')+5, key.indexOf('&par-page=')))
        return fetch(authSession, authenticating === 'loading', { ...paging, page: pageNumber })
    }
    const { data: page, error, mutate, size, setSize, isValidating } = useSWRInfinite(
        keyData, fetchData,       // Ne pas intégrer le key/fetch ici, il faut le laisser dans une fonction du componsant
        { shouldRetryOnError: true, revalidateOnMount: true, initialSize: (initialPaging.page ?? 0) + 1, revalidateFirstPage: false },
    )
    const { data, total: count } = page && page.length > 0 
        ? { data: page.flatMap(p => p.data), total: page[0].total ?? page.flatMap(p => p.data).length } 
        : { data: undefined, total: undefined }

    const nextPage = () => setSize(size+1)

    const resetPage = () => setSize(0)

    const changeSort = (tri: string, ordre: string) => {
        if (tri !== paging.tri || ordre != paging.ordre) {
            setPaging({ ...paging, tri, ordre, v: paging.v + 1 })
            setSize(0)
        }
    }

    return {
        data,
        count,
        error,
        loading: isValidating || data === undefined,
        mutate,
        paging,
        nextPage, 
        resetPage,
        changeSort,
    }
}