import { CampusIntro } from 'atlas-ds';
import Link from 'components/link';
import Head from 'next/head';
import { default as NextLink } from 'next/link';
import { truncate } from 'services/global';

export default function Intro(props: {modulesCount: number}) {
    
    const title = "campusAtlas"
    const description = "L’offre de formations sélectionnées par Atlas pour ses entreprises. Choisissez le parcours adapté à votre besoin."

    return (
        <>
            <Head>
                <title key="__title">{ title }</title>
                <meta property="og:title" content={ title } key="__og:title" />
                <meta name="description" content={ truncate(description) } key="__description" />
                <meta property="og:description" content={ truncate(description, 200) } key="__og:description" />
            </Head>

            <CampusIntro
                title={title}
                description={description}
                moreLink={<Link href="/landing" level={ 2 } label="En savoir plus" />}
                features={[
                    {
                        icon: "module",
                        content: <><span>{ props.modulesCount ?? '' }</span> modules de formation disponibles</>
                    },
                    {
                        icon: "objectifs",
                        content: <>Des programmes conçus avec vos <NextLink href="/branches">branches professionnelles</NextLink></>
                    },
                    {
                        icon: "batiment",
                        content: "Une garantie sur la qualité des organismes de formation"
                    },
                    {
                        icon: "prix",
                        content: "Des tarifs négociés et un financement adapté"
                    }
                ]}
            />
        </>
    )
}

